import React from "react";
import { graphql, Link } from "gatsby";

// Layout
import SecondaryLanding from "../../layouts/secondary-landing";

// Components
import HeroChevron from "../hero/hero-chevron";
import SEO from "../seo/seo";
import Breadcrumb from "../breadcrumb/breadcrumb";
import MortgageCalculator from "../calculators/mortgage-calculator/calculator";
import List from "../custom-widgets/list";
import Button from "../custom-widgets/button";
import FaqAccordion from "../faq/faq-accordion";
import StretchedLinkCards from "../card/stretched-link-cards";
import ServiceStatesLinks from "../service-states/service-states-links";
import WafdBankBenefits from "../wafd-bank-benefits/wafd-bank-benefits";
import ReturningBorrowerLink from "../home-loans/returning-borrower-link";

// Helpers
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import { StaticImage } from "gatsby-plugin-image";

// GraphQL Query
export const query = graphql`
  query stateLotLoansHeroData(
    $slug: String
    $pathXXL: String
    $pathXL: String
    $pathLG: String
    $pathMD: String
    $pathSM: String
    $pathXS: String
    $pathXXS: String
  ) {
    imgVariableXXL: file(relativePath: { eq: $pathXXL }) {
      ...heroChevronImageFragmentXXL
    }
    imgVariableXL: file(relativePath: { eq: $pathXL }) {
      ...heroChevronImageFragmentXL
    }
    imgVariableLG: file(relativePath: { eq: $pathLG }) {
      ...heroChevronImageFragmentLG
    }
    imgVariableMD: file(relativePath: { eq: $pathMD }) {
      ...heroChevronImageFragmentMD
    }
    imgVariableSM: file(relativePath: { eq: $pathSM }) {
      ...heroChevronImageFragmentSM
    }
    imgVariableXS: file(relativePath: { eq: $pathXS }) {
      ...heroChevronImageFragmentXS
    }
    imgVariableXXS: file(relativePath: { eq: $pathXXS }) {
      ...heroChevronImageFragmentXXS
    }
    CardImage1: file(relativePath: { eq: "cards/thumbnail-buying-land-build-house-071023.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    CardImage2: file(relativePath: { eq: "cards/thumbnail-first-time-home-buyers-112123.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    CardImage3: file(relativePath: { eq: "cards/thumbnail-construction-082823.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    strapiBranchState: strapiBranchStates(Slug: { eq: $slug }) {
      StateCode
      StateName
      Slug
    }
  }
`;

const StateLotLoansTemplate = ({ data }) => {
  const stateData = data.strapiBranchState;
  const StateName = stateData.StateName;
  const StateCode = stateData.StateCode;
  const StateSlug = stateData.Slug;

  const heroChevronData = {
    id: "state-lot-loans-hero-chevron",
    ...getHeroImgVariables(data),
    altText: `${StateName} neighborhood new home building.`,
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Put your " + StateName + " dream home right where you want it!"
          }
        },
        {
          id: 2,
          button: {
            id: "state-lot-loans-hero-cta",
            text: "Lot Loan Calculator",
            url: "#mortgage-calculator-section",
            class: "btn-white",
            icon: {
              position: "right",
              lib: "fas",
              name: "calculator",
              class: "float-right mt-1 ml-3"
            }
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: `${StateName} Land Loans`
    }
  ];

  const metaData = {
    AZ: {
      title: "Lot and Loans in Arizona",
      description:
        "Discover the best bank for land loans in Arizona. WaFd Bank offers lot loans to help build your dream home in the Tucson and Phoenix areas and beyond."
    },
    NM: {
      title: "Lot and Loans in New Mexico",
      description:
        "Discover the best bank for land loans in New Mexico. WaFd Bank offers lot loans to help build your dream home in the Albuquerque area and beyond."
    },
    NV: {
      title: "Lot and Loans in Nevada",
      description:
        "Discover the best bank for land loans in Nevada. WaFd Bank offers lot loans financing to help build your dream home in the Las Vegas area and beyond."
    },
    OR: {
      title: "Lot and Loans in Oregon",
      description:
        "Discover the best bank for lot and land loans in Oregon. WaFd Bank offers bare land loans to help you build your dream home today."
    },
    ID: {
      title: "Lot and Loans in Idaho",
      description:
        "Discover the best bank for lot and land loans in Idaho. WaFd Bank offers bare land loans to help you build your dream home today."
    },
    UT: {
      title: "Lot and Loans in Utah",
      description:
        "Discover the best bank for land loans in Utah and check current rates. WaFd Bank offers lot loan financing to help build your dream home."
    },
    WA: {
      title: "Lot and Loans in Washington State",
      description:
        "Discover the best lot and land loan lender in Washington state. WaFd Bank offers raw land and constructions loans to build your dream home today."
    },
    TX: {
      title: "Lot and Loans in Texas",
      description:
        "Discover the best bank for land loans in Texas. WaFd Bank offers lot loans financing to help build your dream home in the Dallas area and beyond."
    }
  };

  const SEOData = {
    title: metaData[StateCode].title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: metaData[StateCode].title
      },
      {
        name: "description",
        property: "og:description",
        content: metaData[StateCode].description
      },
      {
        property: "og:url",
        content:
          "https://www.wafdbank.com/personal-banking/home-loans/land-loans-" +
          (stateData.Slug === "washington" ? "washington-state" : stateData.Slug)
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/states/og-lot-loan-" + StateSlug + "-082323.jpg"
      }
    ]
  };

  const articlesData = {
    sectionClass: "bg-white",
    cards: [
      {
        id: "state-lot-loans-card-01",
        url: "/blog/building-remodeling/buying-land-to-build-house",
        title: "7 Tips for Buying Land to Build a House",
        image: {
          imgVariable: data.CardImage1.childImageSharp.gatsbyImageData,
          altText: "Wood model house in empty field at sunset."
        }
      },
      {
        id: "state-lot-loans-card-02",
        url: "/blog/home-ownership/best-way-to-buy-a-house",
        title: "Best Way to Buy a House for First-Time Home Buyers",
        image: {
          imgVariable: data.CardImage2.childImageSharp.gatsbyImageData,
          altText: "Father and children laughing and playing ball in the yard."
        }
      },
      {
        id: "state-lot-loans-card-03",
        url: `/personal-banking/home-loans/construction-loans/${StateSlug}`,
        title: "Turn your vision into reality with a WaFd Bank construction loan",
        image: {
          imgVariable: data.CardImage3.childImageSharp.gatsbyImageData,
          altText: "Couple reviewing architectural plans with an architect"
        }
      }
    ]
  };

  const AVG_PRICE = 350000;
  const calculatorData = {
    title: StateName + " Lot Loan",
    loanType: "Lot Loan",
    loanTerm: "20 Year Fixed",
    interestRate: 0,
    estimatedPayment: null,
    purchasePrice: AVG_PRICE,
    downPaymentPercent: 30,
    downPaymentAmount: AVG_PRICE * 0.3,
    loanAmount: AVG_PRICE - AVG_PRICE * 0.3
  };

  const faqAccordionData = {
    sectionClass: "pt-0",
    id: `${StateSlug}-land-loans-faq-accordion`,
    seeMoreFaqsLink: "/personal-banking/home-loans/mortgage-faq",
    faqCategoryNames: ["Land Loans"]
  };

  const landLoanChecklistData = {
    greenCircleCheckmark: true,
    accountName: "land-loans-checklist",
    items: [
      {
        id: 1,
        text: "70% LTV, Improved Land"
      },
      {
        id: 2,
        text: "Available on loan amounts up to $700,000"
      },
      {
        id: 3,
        text: "20 year term available"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="pb-0">
        <div className="container">
          <h1>Lot and Land Loans in {StateName}</h1>
          <div className="row my-4">
            <div className="col-md-6">
              <h3>
                With a Lot Loan from WaFd Bank, get the financing you need to buy the perfect spot for your new home.
              </h3>
              <h5 className="text-success">
                Your local {StateName} loan officer can help you get started. WaFd Bank has been helping people build
                their dream homes for more than 100 years.
              </h5>
              <div className="row">
                <div className="col-sm-6 col-md-12 col-lg-6 mb-3">
                  <Button
                    class="btn-link w-100"
                    url="/personal-banking/home-loans/mortgage-rates"
                    text="See Today's Rates"
                    id="rates-btn"
                    showIcon={false}
                  />
                </div>
                <div className="col-sm-6 col-md-12 col-lg-6 mb-3">
                  <Button
                    class="btn-primary w-100"
                    url="/personal-banking/contact-loan-officer"
                    text="Find a Loan Officer"
                    id="find-loan-officer-btn"
                    showIcon={false}
                  />
                </div>
              </div>
              <ReturningBorrowerLink loanType="Lot Loan" />
            </div>
            <div className="col-md-6">
              <h5>Land Loan Checklist</h5>
              <List {...landLoanChecklistData} />
              <p>
                Consider a WaFd short-term lot loan and receive a discount on closing costs when you finance{" "}
                <Link
                  to={"/personal-banking/home-loans/construction-loans/" + stateData.Slug}
                  id="state-construction-page-link"
                >
                  construction
                </Link>{" "}
                of your custom home with us within two years. We offer a 20-year term lot loan for long-term plans.
                Apply online, where you can upload your documents and receive status updates in our secure portal.
              </p>
            </div>
          </div>
        </div>
      </section>

      <MortgageCalculator {...calculatorData} />

      <section className="bg-info text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../../images/cards/personalization/thumbnail-closing-costs-03152023-730.jpg"
                alt="Young couple moving in new house, holding keys"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h3>Save Up to $600 on Closing Costs With a Checking Account From WaFd Bank*</h3>
              <Button
                class="btn-white w-100 w-sm-auto"
                url="/personal-banking/home-loans/closing-cost-checking-account-offer"
                text="Find out how!"
                id="closing-cost-stripe-cta"
                showIcon={false}
                containerClass="mb-4"
              />
              <p className="text-sm" id="closing-cost-disclaimer">
                * To qualify for the maximum $600 discount, before closing documents are prepared you must (1) have or
                open the WaFd checking account, (2) register for EZ-Pay from the new checking account, and (3) sign up
                for direct deposit of at least one form of a monthly recurring source of income.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-light">
        <WafdBankBenefits />
      </div>
      <StretchedLinkCards {...articlesData} />
      <FaqAccordion {...faqAccordionData} />
      <section className="container">
        <div className="row justify-content-center text-center mb-4">
          <div className="col-lg-8">
            <h2 className="text-success">Want more help? Come See Us</h2>
            <p>
              If you've done all your research but aren't sure whether a lot loan makes sense, just stop by. We're
              nearby, and we'll sit down with you and answer any questions you may have.
            </p>
            <ServiceStatesLinks />
          </div>
        </div>
        <p className="text-muted text-sm" id="land-loans-disclaimer">
          * To qualify for the maximum $600 discount, before closing documents are prepared you must (1) have or open
          the WaFd checking account, (2) register for EZ-Pay from the new checking account, and (3) sign up for direct
          deposit of at least one form of a monthly recurring source of income.
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default StateLotLoansTemplate;
